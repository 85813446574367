import React from 'react';
import './footer.css'

const Footer = () =>{
    return (
        <section className="Footer">
            <p className="Footer__text">Made with ❤ by Salaxer</p>
            <p className="Footer__text">Last Update on May 2024</p>
        </section>
    )
}
export default Footer;