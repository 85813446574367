import React from 'react';

const NotFound = () => {
  return (
    <div className="all" style={{color: 'white'}}>
      Hola como estas no hay nada aquí  saludos
    </div>
  );
};

export default NotFound;